import React from 'react';
import styles from '../../../message-container.scss';
import Button from '../../button';
import Text from '../../text';
export default ({ content, textColor, bgColor, color, isRightSide, style, handleClickButton, fontSize, children }) => {
  const { text } = content;
  return (
    <div className={styles.textButton}>
      {text && (
        <div className={styles.textBox} style={{ color: bgColor }}>
          <Text textColor={textColor} bgColor="transparent" content={text} style={style} fontSize={fontSize}></Text>
        </div>
      )}
      <div className={styles.button_box}>
        <Button content={'Notify me'} handleClickButton={handleClickButton} textColor={color} width="auto" isRightSide={isRightSide} />
      </div>
      {children}
    </div>
  );
};
