import React from "react";
import noAvatar from "../icons/no-ava-02.svg";
import SVG from "react-inlinesvg";
const Avatar = ({ src, size }) => {
  return (
    <div>
      {src ? (
        <img
          style={{ width: size, height: size, borderRadius: "50%" }}
          src={src}
        />
      ) : (
        <SVG style={{ width: size, height: size }} src={noAvatar} />
      )}
    </div>
  );
};

export default Avatar;
