import React from 'react';
import styles from './styles.scss';
function Comment({ content, textColor, bgColor, isRightSide }) {
  const { text, postId, postLink } = content || {};

  return (
    <div
      style={{
        color: textColor,
        backgroundColor: bgColor,
      }}
      className={styles.content}
    >
      <div className={styles.postId}>
        Commented on&nbsp;
        <a href={postLink} target="_blank">
          post#{postId}
        </a>
      </div>
      <span> {text}</span>
    </div>
  );
}

export default Comment;
