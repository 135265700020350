import React from 'react';
import 'emoji-mart/css/emoji-mart.css';
import { Emoji } from 'emoji-mart';
var get = require('lodash.get');

import { toArray } from '../../../../utils/parse-emoji';
import Linkify from 'react-linkify';
import StyleOne from './styles/style-one';
import StyleTwo from './styles/style-two';
import StyleThree from './styles/style-three';

function parseEmoji(text) {
  if (typeof text === 'string') {
    const emojisArray = toArray(text);
    return emojisArray.map((e, i) => {
      if (typeof e === 'string') {
        return e;
      } else {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: Emoji({
                html: true,
                set: 'emojione',
                emoji: e.value,
                size: 20,
              }),
            }}
            key={i}
          ></span>
        );
      }
    });
  }
}

const linkDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank">
    {text}
  </a>
);

const Text = ({ content, textColor, bgColor, style, children, textAlign, isShowSeenTime, isRightSide, fontSize, style3BorderRadius }) => {
  if (typeof content !== 'string') {
    content = get(content, 'text') || content;
  }
  return style === 3 ? (
    <StyleThree
      textColor={textColor}
      bgColor={bgColor}
      textAlign={textAlign}
      isShowSeenTime={isShowSeenTime}
      isRightSide={isRightSide}
      fontSize={fontSize}
    >
      {content && <Linkify componentDecorator={linkDecorator}>{parseEmoji(content)}</Linkify>}
      {children}
    </StyleThree>
  ) : style === 2 ? (
    <StyleTwo
      textColor={textColor}
      bgColor={bgColor}
      textAlign={textAlign}
      isShowSeenTime={isShowSeenTime}
      isRightSide={isRightSide}
      fontSize={fontSize}
      style3BorderRadius={style3BorderRadius}
    >
      {content && <Linkify componentDecorator={linkDecorator}>{parseEmoji(content)}</Linkify>}
      {children}
    </StyleTwo>
  ) : (
    <StyleOne
      textColor={textColor}
      bgColor={bgColor}
      textAlign={textAlign}
      isShowSeenTime={isShowSeenTime}
      isRightSide={isRightSide}
      fontSize={fontSize}
    >
      {content && <Linkify componentDecorator={linkDecorator}>{parseEmoji(content)}</Linkify>}
      {children}
    </StyleOne>
  );
};

export default Text;
