import React, { Component } from 'react';
import styles from '../../message-container.scss';
import Card from '../card/index';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';
import SVG from 'react-inlinesvg';
import SVGNext from '../icons/next-arrow-icon.svg';
import SVGPrev from '../icons/prev-arrow-icon.svg';
class Carousel extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const { content, color, isShowLeftAvatar, marginLeft, handleClickButton, children, isRightSide } = this.props;
    const { cards } = content;
    const settings = {
      className: 'center',
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      variableWidth: true,
    };
    return (
      <div
        className={styles.carousel}
        style={{
          marginLeft: !isShowLeftAvatar && marginLeft,
          position: 'relative',
        }}
      >
        <Slider {...settings} ref={(c) => (this.slider = c)}>
          {(cards || []).map((value, index) => {
            let content = {
              id: value.id,
              image: value.image,
              title: value.title,
              buttons: value.buttons,
              subtitle: value.subtitle,
            };
            return (
              <div key={`carousel_${index}`} style={{ marginRight: '12px' }}>
                <Card content={content} marginRight="12px" color={color} handleClickButton={handleClickButton} isRightSide={isRightSide}></Card>
              </div>
            );
          })}
        </Slider>
        <div onClick={this.previous} className={styles.buttonPrev} style={{ backgroundColor: color }}>
          <SVG src={SVGPrev} style={{ color: '#fff', width: '8px' }} />
        </div>
        <div onClick={this.next} className={styles.buttonNext} style={{ backgroundColor: color }}>
          <SVG src={SVGNext} style={{ color: '#fff', width: '8px' }} />
        </div>
        {children}
      </div>
    );
  }
}

export default Carousel;
