import React from 'react';
import Text from '../text';
import styles from './styles.scss';

export default ({ textColor, bgColor, style, isRightSide, style3BorderRadius }) => {
  return (
    <Text textColor={textColor} bgColor={bgColor} style={style} isRightSide={isRightSide} style3BorderRadius={style3BorderRadius}>
      <div className={styles.ticontainer}>
        <div style={{ color: textColor }} className={styles.tiblock}>
          <div className={styles.tidot}></div>
          <div className={styles.tidot}></div>
          <div className={styles.tidot}></div>
        </div>
      </div>
    </Text>
  );
};
