import React, { Component } from "react";
import { Player, BigPlayButton } from "video-react";
import "video-react/dist/video-react.css";
import styles from "../../message-container.scss";
import "./index.css";
var get = require("lodash.get");

function getYoutubeId(url) {
  if (!url) {
    return undefined;
  }
  url = url.trim();
  url = url.replace("https://www.youtube.com/watch?v=", "");
  return url;
}

export default class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: null,
      originURL: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      get(nextProps, "content.url") &&
      nextProps.content.url !== prevState.originURL
    ) {
      return {
        url: nextProps.content.url,
        originURL: nextProps.content.url,
      };
    }
    return null;
  }

  onURLError = async () => {
    const { onError, messageId } = this.props;
    if (!onError || !messageId) return;
    const data = await onError("MESSAGE", { messageId });
    this.setState({
      url: get(data, "url"),
    });
  };

  render() {
    const { url } = this.state;
    const opts = {
      height: "250",
      width: "300",
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };
    return (
      <div className={styles.video}>
        <Player src={url} onError={this.onURLError}>
          <BigPlayButton position="center" />
        </Player>
      </div>
    );
  }
  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}
