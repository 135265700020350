import React from 'react';
import styles from '../../message-container.scss';
import Button from '../button';

const Card = ({ content, color, marginRight, handleClickButton, children, isRightSide }) => {
  const { title, subtitle, image, buttons } = content;
  return (
    <div className={styles.card} style={{ marginRight: marginRight && marginRight, position: 'relative' }}>
      <div className={styles.card_content}>
        {image && <img className={styles.image} src={image} />}
        <div className={styles.card_title_box}>
          {title && (
            <div>
              <span className={styles.cardTitle}>{title}</span>
            </div>
          )}
          {subtitle && (
            <div>
              <span className={styles.subtitle}>{subtitle}</span>
            </div>
          )}
        </div>
        <div className={styles.button_box}>
          {(buttons || [])
            .filter((btn) => btn.label)
            .map((value, index) => {
              return (
                <Button
                  key={`button_${index}`}
                  content={value}
                  handleClickButton={handleClickButton}
                  textColor={color}
                  width="100%"
                  isRightSide={isRightSide}
                />
              );
            })}
        </div>
        {children}
      </div>
    </div>
  );
};

export default Card;
