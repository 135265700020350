import React from "react";
import styles from "../../message-container.scss";
var get = require("lodash.get");


class Sticker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: null,
      originURL: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      get(nextProps, "content.url") &&
      nextProps.content.url !== prevState.originURL
    ) {
      return {
        url: nextProps.content.url,
        originURL: nextProps.content.url,
      };
    }
    return null;
  }

  onImgError = async () => {
    const { onError, messageId } = this.props;
    if (!onError || !messageId) return;
    const data = await onError("MESSAGE", { messageId });
    this.setState({
      url: get(data, "url"),
    });
  };

  render() {
    const { children } = this.props;
    const { url } = this.state;
    return (
      <div className={styles.image}>
        <img style={{ maxWidth: "70px" }} src={url} onError={this.onImgError} />
        {children}
      </div>
    );
  }
}
export default Sticker;
