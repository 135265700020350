import React from 'react';
import styles from '../../../message-container.scss';
import Button from '../../button';
import Text from '../../text';
export default ({ content, textColor, bgColor, color, isRightSide, style, handleClickButton, fontSize, children, style3BorderRadius }) => {
  const { text, buttons } = content;
  return (
    <div className={styles.textButton}>
      {text && (
        <div
          className={styles.textBox}
          style={{ color: bgColor, borderRadius: style3BorderRadius ? `${style3BorderRadius}px ${style3BorderRadius}px 0 0` : '10px 10px 0 0' }}
        >
          <Text textColor={textColor} bgColor="transparent" content={text} style={style} fontSize={fontSize}></Text>
        </div>
      )}
      <div
        className={styles.button_box}
        style={{
          borderRadius: style3BorderRadius ? `0 0 ${style3BorderRadius}px ${style3BorderRadius}px` : '0 0 10px 10px',
        }}
      >
        {(buttons || [])
          .filter((btn) => btn.label)
          .map((value, index) => {
            return (
              <Button
                key={`button_${index}`}
                content={value}
                handleClickButton={handleClickButton}
                textColor={color}
                width="auto"
                isRightSide={isRightSide}
              />
            );
          })}
      </div>
      {children}
    </div>
  );
};
