import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './message-container.scss';
import ScrollBars from 'react-custom-scrollbars';
import Text from './components/text';
import QuickReply from './components/quick-reply';
import Card from './components/card';
import Carousel from './components/carousel';
import Image from './components/image';
import Sticker from './components/sticker';
import Video from './components/video';
import File from './components/file';
import Typing from './components/typing';
import TextWithButton from './components/text-with-button';
import OTNR from './components/otnr';
const loading = 'https://storage.googleapis.com/bs-production-public/assets/home-id/ZZ5H.gif';
import Avatar from './components/avatar';
const defaultAvatarWidth = 25;
const defaultAvatarMargin = 5;
const defaultSenderNameSize = 12;
const defaultFontSize = 16;
import Status from './components/status';
import './overwrite.css';
import Comment from './components/comment';
var get = require('lodash.get');
var isEmpty = require('lodash.isempty');

class MessageContainer extends Component {
  constructor(props) {
    super(props);
    this.handleOnScrollFrame = this.handleOnScrollFrame.bind(this);
    this.state = {
      participantMap: [],
      isAtBottom: false,
    };
  }

  scrollToBottom() {
    this.scrollbarRef && this.scrollbarRef.scrollToBottom();
  }

  isAtBottom() {
    return this.state.isAtBottom;
  }

  componentDidMount() {
    const { messages, participants } = this.props;
    this.updateParticipantMapFromProps(this.props);
  }

  updateParticipantMapFromProps = (props) => {
    if (!props) return;
    const { participants } = props;
    const participantMap = {};
    participants.forEach((p) => (participantMap[`${p.id}`] = p));
    this.setState({
      participantMap,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateParticipantMapFromProps(nextProps);
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (JSON.stringify(this.props.participants) !== JSON.stringify(nextProps.participants)) {
      this.updateParticipantMapFromProps(nextProps);
    }
  }

  isMessageContentReady = () => {
    return false;
  };

  getMessageStyle = (list ,index)=>{
    if(list.length && index === list.length - 1){
      return  `${styles.message} ${styles.last} `
    }
    return `${styles.message}`;
  }

  handleClickButton = (button) => {
    // console.log('handleClickButton:', button);
    this.props.onClickButton && this.props.onClickButton(button);
  };

  renderMessage(message, index, leftParitcipantsTextColor, leftParitcipantsTextBgColor, rightParitcipantsTextColor, rightParitcipantsTextBgColor,messages) {
    const { participantMap } = this.state;

    const {
      isShowLeftAvatar,
      isShowRightAvatar,
      isShowMessageStatus,
      isShowRightSender,
      onURLError,
      isShowSeenTime,
      style3BorderRadius,
    } = this.props;
    const isWaitingForData = isEmpty(get(participantMap, `${message.senderId}.side`));
    const isRightSide = get(participantMap, `${message.senderId}.side`) === 'right';
    if (!message || !message.message) {
      return <div></div>;
    }
    const {
      senderId,
      message: { id, type, content, isShowStatus, status, isHideMessage },
      error,
    } = message;

    const isShowConversationStatus = isRightSide && isShowMessageStatus && (isShowStatus || get(message, 'isShowStatus'));
    const isHideConversationMessage = isHideMessage || get(message, 'isHideMessage');
    const sender = participantMap[`${senderId}`];
    const reader = find(participantMap, ['side', 'left']);
    const textColor = isRightSide ? rightParitcipantsTextColor : leftParitcipantsTextColor;
    const bgColor = isRightSide ? rightParitcipantsTextBgColor : leftParitcipantsTextBgColor;

    let switchRenderObject = (param) => {
      switch (param) {
        case 'quick-reply':
          return (
            <div className={this.getMessageStyle(messages,index)}>
              <QuickReply
                textColor={textColor}
                bgColor={bgColor}
                color={rightParitcipantsTextBgColor}
                content={content}
                style={this.props.style}
                style3BorderRadius={style3BorderRadius}
                contentAlign={isRightSide ? 'right' : undefined}
                handleClickButton={this.handleClickButton.bind(this)}
                isHideButtons={isHideConversationMessage}
                fontSize={defaultFontSize}
                isRightSide={isRightSide}
              >
                {isShowConversationStatus && (
                  <Status
                    id={get(message, 'id')}
                    color={bgColor}
                    status={status || get(message, 'status')}
                    avatar={get(reader, 'avatar')}
                    errorMessage={get(error, 'message')}
                    onURLError={onURLError}
                    subscriberId={get(reader, 'id')}
                  />
                )}
              </QuickReply>
            </div>
          );
        case 'text':
          return (
            <div className={this.getMessageStyle(messages,index)}>
              <Text
                textColor={textColor}
                bgColor={bgColor}
                content={content}
                style={this.props.style}
                style3BorderRadius={style3BorderRadius}
                textAlign={isRightSide && 'left'}
                isShowSeenTime={isShowSeenTime}
                isRightSide={isRightSide}
                fontSize={defaultFontSize}
              >
                {isShowConversationStatus && (
                  <Status
                    id={get(message, 'id')}
                    color={bgColor}
                    status={status || get(message, 'status')}
                    avatar={get(reader, 'avatar')}
                    errorMessage={get(error, 'message')}
                    onURLError={onURLError}
                    subscriberId={get(reader, 'id')}
                  />
                )}
              </Text>
            </div>
          );
        case 'card':
          return (
            <div className={this.getMessageStyle(messages,index)}>
              <Card
                textColor={textColor}
                bgColor={bgColor}
                content={content}
                color={rightParitcipantsTextBgColor}
                handleClickButton={this.handleClickButton.bind(this)}
                isRightSide={isRightSide}
              >
                {isShowConversationStatus && (
                  <Status
                    id={get(message, 'id')}
                    color={bgColor}
                    status={status || get(message, 'status')}
                    avatar={get(reader, 'avatar')}
                    errorMessage={get(error, 'message')}
                    onURLError={onURLError}
                    subscriberId={get(reader, 'id')}
                  />
                )}
              </Card>
            </div>
          );
        case 'carousel':
          return (
            <div className={this.getMessageStyle(messages,index)}>
              <Carousel
                content={content}
                color={rightParitcipantsTextBgColor}
                isShowLeftAvatar={isShowLeftAvatar}
                marginLeft={avatarWrapperWidth}
                handleClickButton={this.handleClickButton.bind(this)}
                isRightSide={isRightSide}
              >
                {isShowConversationStatus && (
                  <Status
                    id={get(message, 'id')}
                    color={bgColor}
                    status={status || get(message, 'status')}
                    avatar={get(reader, 'avatar')}
                    errorMessage={get(error, 'message')}
                    onURLError={onURLError}
                    subscriberId={get(reader, 'id')}
                  />
                )}
              </Carousel>
            </div>
          );
        case 'image':
          return (
            <div className={this.getMessageStyle(messages,index)}>
              <Image content={content} onError={onURLError} messageId={get(message, 'id')}>
                {isShowConversationStatus && (
                  <Status
                    id={get(message, 'id')}
                    color={bgColor}
                    status={status || get(message, 'status')}
                    avatar={get(reader, 'avatar')}
                    errorMessage={get(error, 'message')}
                    onURLError={onURLError}
                    subscriberId={get(reader, 'id')}
                  />
                )}
              </Image>
            </div>
          );
        case 'video':
          return (
            <div className={this.getMessageStyle(messages,index)}>
              <Video content={content} onError={onURLError} messageId={get(message, 'id')} />
            </div>
          );
        case 'sticker':
          return (
            <div className={this.getMessageStyle(messages,index)}>
              <Sticker content={content} onError={onURLError} messageId={get(message, 'id')}>
                {isShowConversationStatus && (
                  <Status
                    id={get(message, 'id')}
                    color={bgColor}
                    status={status || get(message, 'status')}
                    avatar={get(reader, 'avatar')}
                    errorMessage={get(error, 'message')}
                    onURLError={onURLError}
                    subscriberId={get(reader, 'id')}
                  />
                )}
              </Sticker>
            </div>
          );
        case 'file':
          return (
            <div className={this.getMessageStyle(messages,index)}>
              <File
                textColor={textColor}
                bgColor={bgColor}
                content={content}
                onError={onURLError}
                messageId={get(message, 'id')}
                style={this.props.style}
                style3BorderRadius={style3BorderRadius}
              >
                {isShowConversationStatus && (
                  <Status
                    id={get(message, 'id')}
                    color={bgColor}
                    status={status || get(message, 'status')}
                    avatar={get(reader, 'avatar')}
                    errorMessage={get(error, 'message')}
                    onURLError={onURLError}
                    subscriberId={get(reader, 'id')}
                  />
                )}
              </File>
            </div>
          );
        case 'delay':
          return (
            <div className={this.getMessageStyle(messages,index)}>
              <Typing
                textColor={textColor}
                bgColor={bgColor}
                content={content}
                style={this.props.style}
                style3BorderRadius={style3BorderRadius}
                textAlign={isRightSide ? 'right' : undefined}
              />
            </div>
          );
        case 'text-with-button':
          return (
            <div className={this.getMessageStyle(messages,index)}>
              <TextWithButton
                fontSize={defaultFontSize}
                textColor={textColor}
                bgColor={bgColor}
                color={rightParitcipantsTextBgColor}
                content={content}
                style={this.props.style}
                style3BorderRadius={style3BorderRadius}
                isRightSide={isRightSide}
                handleClickButton={this.handleClickButton.bind(this)}
              >
                {isShowConversationStatus && (
                  <Status
                    id={get(message, 'id')}
                    color={bgColor}
                    status={status || get(message, 'status')}
                    avatar={get(reader, 'avatar')}
                    errorMessage={get(error, 'message')}
                    onURLError={onURLError}
                    subscriberId={get(reader, 'id')}
                  />
                )}
              </TextWithButton>
            </div>
          );
        case 'otnr':
          return (
            <div className={this.getMessageStyle(messages,index)}>
              <OTNR
                fontSize={defaultFontSize}
                textColor={textColor}
                bgColor={bgColor}
                color={rightParitcipantsTextBgColor}
                content={content}
                style={this.props.style}
                style3BorderRadius={style3BorderRadius}
                isRightSide={isRightSide}
                handleClickButton={this.handleClickButton.bind(this)}
              >
                {isShowConversationStatus && (
                  <Status
                    id={get(message, 'id')}
                    color={bgColor}
                    status={status || get(message, 'status')}
                    avatar={get(reader, 'avatar')}
                    errorMessage={get(error, 'message')}
                    onURLError={onURLError}
                    subscriberId={get(reader, 'id')}
                  />
                )}
              </OTNR>
            </div>
          );
        case 'comment':
          return (
            <div className={this.getMessageStyle(messages,index)}>
              <Comment textColor={textColor} bgColor={bgColor} content={content} isRightSide={isRightSide} />
            </div>
          );
      }
    };

    const avatarWrapperWidth = (this.props.avatarWidth || defaultAvatarWidth) + (this.props.avatarMargin || defaultAvatarMargin);

    const isShowAvatar = (isRightSide && isShowRightAvatar) || (!isRightSide && isShowLeftAvatar);
    // type === "quick-reply" ||
    const isShowSenderName = isRightSide && isShowRightSender;
    const isShowMessage = !isHideConversationMessage || type === 'quick-reply';
    const key = `message_${id || index}`;
    // console.log('key: ', key);
    return !isWaitingForData ? (
      <React.Fragment key={key}>
        {isShowMessage && (
          <div
            className={isRightSide ? `${styles.messageRow} ${styles.right} ` : styles.messageRow}
            style={{
              display: 'flex',
              marginBottom: `${this.props.messageMargin || 20}px`,
              marginTop: index === 0 && isShowRightSender ? 20 : 5,
            }}
          >
            {isShowAvatar && (
              <div className={styles.avatar} style={{ width: `${avatarWrapperWidth}px` }}>
                {sender && sender.avatar ? (
                  <Avatar size={this.props.avatarWidth || defaultAvatarWidth} src={sender.avatar} />
                ) : (
                  <Avatar size={this.props.avatarWidth || defaultAvatarWidth} />
                )}
                {isShowSenderName && (
                  <span
                    style={{
                      top: -(defaultSenderNameSize + 5),
                      right: avatarWrapperWidth,
                      fontSize: defaultSenderNameSize,
                    }}
                    className={styles.senderName}
                  >
                    {sender.name}
                  </span>
                )}
              </div>
            )}
            {switchRenderObject(type)}
            <div className={styles.avatar} style={{ width: `${avatarWrapperWidth}px` }}></div>
          </div>
        )}
      </React.Fragment>
    ) : (
      <div key={key}></div>
    );
  }

  renderEmpty() {
    return <div></div>;
  }

  handleOnScrollFrame(values) {
    const { top } = values;
    if (top === 0) {
      this.props.onScrollTop();
      this.setState({
        isAtBottom: false,
      });
    } else if (top >= 0.98) {
      this.setState({
        isAtBottom: true,
      });
    } else {
      this.setState({
        isAtBottom: false,
      });
    }
  }

  renderLeftTyping() {
    const { style3BorderRadius } = this.props;
    return (
      <div
        className={styles.messageRow}
        style={{
          display: 'flex',
          marginBottom: `${this.props.messageMargin || 20}px`,
        }}
      >
        <div className={styles.message}>
          <Typing
            textColor={this.props.leftParitcipantsTextColor}
            bgColor={this.props.leftParitcipantsTextBgColor}
            style={this.props.style}
            isRightSide={false}
            style3BorderRadius={style3BorderRadius}
          ></Typing>
        </div>
      </div>
    );
  }

  renderRightTyping() {
    const { style3BorderRadius } = this.props;
    return (
      <div
        className={`${styles.messageRow} ${styles.right}`}
        style={{
          display: 'flex',
          marginBottom: `${this.props.messageMargin || 20}px`,
        }}
      >
        <div className={styles.message}>
          <Typing
            textColor={this.props.rightParitcipantsTextColor}
            bgColor={this.props.rightParitcipantsTextBgColor}
            style={this.props.style}
            isRightSide={true}
            style3BorderRadius={style3BorderRadius}
          ></Typing>
        </div>
      </div>
    );
  }

  render() {
    let {
      messages,
      leftParitcipantsTextColor,
      leftParitcipantsTextBgColor,
      rightParitcipantsTextColor,
      rightParitcipantsTextBgColor,
      isLoadingHistory,
      isLeftTyping,
      isRightTyping,
    } = this.props;
    return (
      <div className={`${styles.messageContainer} message-container`} style={{ backgroundColor: `${this.props.containerBgColor || 'white'}` }}>
        <ScrollBars
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
          }}
          renderTrackHorizontal={(props) => <div {...props} className="track-horizontal" style={{ display: 'none' }} />}
          onScrollFrame={this.handleOnScrollFrame}
          autoHide={true}
          universal={true}
          ref={(el) => (this.scrollbarRef = el)}
        >
          {isLoadingHistory && (
            <div className={styles.spinContainer}>
              <img style={{ width: '20px', height: '20px' }} src={loading} />
            </div>
          )}
          {messages && messages.length > 0
            ? messages.map((message, index) =>
                this.renderMessage(
                  message,
                  index,
                  leftParitcipantsTextColor,
                  leftParitcipantsTextBgColor,
                  rightParitcipantsTextColor,
                  rightParitcipantsTextBgColor,
                  messages
                ),
              )
            : this.renderEmpty()}

          {isLeftTyping && this.renderLeftTyping()}
          {isRightTyping && this.renderRightTyping()}
        </ScrollBars>
      </div>
    );
  }
}

MessageContainer.propTypes = {
  isOpen: PropTypes.bool,
  messages: PropTypes.arrayOf(Object),
  isLoadingHistory: PropTypes.bool,
};

MessageContainer.defaultProps = {
  isOpen: true,
  isLoadingHistory: false,
  messages: [],
};

export default MessageContainer;
