import replace from "string-replace-to-array";
import asciiRegex from "./asciiRegex";
import aliasRegex from "./aliasRegex";
import aliases from "./data/aliases";
import asciiAliases from "./data/asciiAliases";
var toPairs = require("lodash.topairs");
var keys = require("lodash.keys");
var find = require("lodash.find");
import emojiRegex from "emoji-regex";
import { emojis } from "emoji-mart/data/messenger.json";
const messengerEmojiKeys = keys(emojis);
const aliasPairs = toPairs(aliases);
const asciiAliasesRegex = asciiRegex();
const aliasesRegex = aliasRegex();
const unicodeEmojiRegex = emojiRegex();

export function toArray(text, options = {}) {
  function replaceAsciiAliases(...match) {
    const asciiAliasKeys = Object.keys(asciiAliases);

    const fullMatch = match[0];
    const edgeCase = match[1];
    const asciiAlias = match[2];
    const maybeBiggerAliasCharacters = match[3];

    for (let i in asciiAliasKeys) {
      const alias = asciiAliasKeys[i];
      const data = asciiAliases[alias];

      if (data.includes(asciiAlias)) {
        const isEdgeCase = edgeCase !== undefined;

        if (isEdgeCase) {
          return fullMatch; // do nothing
        }

        const isMaybePartOfBiggerAlias =
          maybeBiggerAliasCharacters !== undefined;

        if (!isMaybePartOfBiggerAlias) {
          return `:${alias}:`; // asciiAlias transformed in alias to be replaced afterwards by aliasRegex
        }

        const fullMatchContent = fullMatch.slice(1, -1); // remove ":" at the beginning and end
        const isPartOfBiggerAlias = aliases[fullMatchContent] !== undefined; // ":" + fullMatchContent + ":" alias doesn't exist

        if (isPartOfBiggerAlias) {
          return fullMatch; // do nothing
        }

        return `:${alias}:${maybeBiggerAliasCharacters}`; // also return matched characters afterwards to handle them in next iteration
      }
    }
  }

  // We need to execute several times `string.replace` for cases for such as ":):)"
  // As we are forced to match ":):" to check if it's a normal alias, the second colon is consumed and cannot match again
  function replaceAllAsciiAliases(textWithAsciiAliases) {
    let previousTextWithoutAsciiAliases = null;
    let textWithoutAsciiAliases = textWithAsciiAliases;

    while (previousTextWithoutAsciiAliases !== textWithoutAsciiAliases) {
      previousTextWithoutAsciiAliases = textWithoutAsciiAliases;
      textWithoutAsciiAliases = textWithoutAsciiAliases.replace(
        asciiAliasesRegex,
        replaceAsciiAliases
      );
    }

    return textWithoutAsciiAliases;
  }

  function convertAliasToObject(...match) {
    return {
      value: match[0],
      type: "alias",
    };
  }

  let textWithouAsciiAliases = replaceAllAsciiAliases(text);
  textWithouAsciiAliases = textWithouAsciiAliases.replace(
    unicodeEmojiRegex,
    (match) => {
      const matches = [];
      for (const pair of aliasPairs) {
        if (pair[1] === match) {
          matches.push(pair[0]);
        }
      }

      for (const m of matches) {
        if (find(messengerEmojiKeys, (key) => key === m)) {
          return `:${m}:`;
        }
      }

      return match;
    }
  );

  return replace(textWithouAsciiAliases, aliasesRegex, convertAliasToObject);
}
