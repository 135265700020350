import React from 'react';
import SVG from 'react-inlinesvg';
import SVGDelivered from '../icons/delivered.svg';
import SVGError from '../icons/error.svg';
import SVGSending from '../icons/sending.svg';
import SVGSent from '../icons/sent.svg';
import SVGNoAvatar from '../icons/no_ava.svg';
import styles from './styles.scss';
var get = require('lodash.get');
var isEmpty = require('lodash.isempty');
import ReactTooltip from 'react-tooltip';

class Status extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: null,
      originURL: null,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (get(nextProps, 'avatar') && nextProps.avatar !== prevState.originURL) {
      return {
        url: nextProps.avatar,
        originURL: nextProps.avatar,
      };
    }
    return null;
  }

  onError = async () => {
    const { onURLError, subscriberId } = this.props;
    if (!onURLError || !subscriberId) return;
    const data = await onURLError('SUBSCRIBER_AVATAR', { subscriberId });
    this.setState({
      url: get(data, 'url'),
    });
  };

  render() {
    const { status, color, errorMessage, id } = this.props;
    const { url } = this.state;
    if (status === 'DELIVERIED') {
      return (
        <div style={{ color: color }} className={styles.icon}>
          <SVG src={SVGDelivered} />
        </div>
      );
    } else if (status === 'SENT') {
      return (
        <div style={{ color: color }} className={styles.icon}>
          <SVG src={SVGSent} />
        </div>
      );
    } else if (status === 'FAILED') {
      return (
        <div className={styles.icon} data-tip data-for={`errorMessage_${id}`}>
          <SVG src={SVGError} />
          <ReactTooltip id={`errorMessage_${id}`} effect="solid" textColor="#fff" backgroundColor="#040024" place="left">
            <span className={styles.tooltiptext}>{errorMessage || 'Failed to send message'}</span>
          </ReactTooltip>
        </div>
      );
    } else if (status === 'READ') {
      return (
        <div style={{ color: color }} className={styles.icon}>
          {!isEmpty(url) ? <img className={styles.avatar} src={url} onError={this.onError} /> : <SVG src={SVGNoAvatar} />}
        </div>
      );
    } else {
      return (
        <div style={{ color: color }} className={styles.icon}>
          <SVG src={SVGSending} />
        </div>
      );
    }
  }
}
export default Status;
