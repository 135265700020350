import React from 'react';
import styles from '../../message-container.scss';
import Button from '../button';
import Text from '../text';
const QuickReply = ({
  content,
  textColor,
  bgColor,
  color,
  contentAlign,
  style,
  handleClickButton,
  isHideButtons,
  fontSize,
  children,
  isRightSide,
  style3BorderRadius,
}) => {
  const { text, buttons } = content;

  return (
    <div className={styles.quickreply}>
      {text && (
        <Text
          textColor={textColor}
          bgColor={bgColor}
          content={text}
          isTextAlignRight={contentAlign === 'right'}
          style={style}
          fontSize={fontSize}
          style3BorderRadius={style3BorderRadius}
        ></Text>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginTop: '10px',
          width: '100%',
          // justifyContent: contentAlign === "right" ? "flex-end" : undefined,
          justifyContent: 'center',
        }}
      >
        {!isHideButtons &&
          (buttons || [])
            .filter((btn) => btn.label)
            .map((button, index) => (
              <div
                key={`button_${index}`}
                style={{
                  marginRight: contentAlign !== 'right' && '8px',
                  marginLeft: contentAlign === 'right' && '8px',
                  marginBottom: '5px',
                }}
              >
                <Button content={button} handleClickButton={handleClickButton} textColor={color} isRightSide={isRightSide} />
              </div>
            ))}
      </div>
      {children}
    </div>
  );
};

export default QuickReply;
