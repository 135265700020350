import React from 'react';
import StyleOne from './styles/style-one';
import StyleTwo from './styles/style-two';
import StyleThree from './styles/style-three';

const OTNR = ({ content, textColor, bgColor, color, isRightSide, style, handleClickButton, fontSize, children, style3BorderRadius }) => {
  return style === 3 ? (
    <StyleThree
      textColor={textColor}
      bgColor={bgColor}
      isRightSide={isRightSide}
      content={content}
      color={color}
      handleClickButton={handleClickButton}
      fontSize={fontSize}
    >
      {children}
    </StyleThree>
  ) : style === 2 ? (
    <StyleTwo
      textColor={textColor}
      bgColor={bgColor}
      isRightSide={isRightSide}
      content={content}
      color={color}
      handleClickButton={handleClickButton}
      fontSize={fontSize}
      style3BorderRadius={style3BorderRadius}
    >
      {children}
    </StyleTwo>
  ) : (
    <StyleOne
      textColor={textColor}
      bgColor={bgColor}
      isRightSide={isRightSide}
      content={content}
      color={color}
      handleClickButton={handleClickButton}
      fontSize={fontSize}
    >
      {children}
    </StyleOne>
  );
};

export default OTNR;
