import React from 'react';
import styles from '../../message-container.scss';
import fileStyles from './file.styles.scss';
import SVG from 'react-inlinesvg';
import SVGDown from '../icons/import-icon.svg';
import SVGFile from '../icons/file.svg';

const File = ({ content, textColor, bgColor, children, style, style3BorderRadius }) => {
  return (
    <div style={{ color: textColor, backgroundColor: bgColor, borderRadius: style === 2 && style3BorderRadius }} className={styles.text}>
      <a href={content.url} target="_blank" className={fileStyles.textContent} style={{ color: textColor }}>
        <SVG className={fileStyles.fileIcon} src={SVGFile} style={{ color: textColor }} />
        <span className={fileStyles.text}> {content.name} </span>
        <SVG src={SVGDown} className={fileStyles.downIcon} style={{ color: textColor }} />
      </a>
      {children}
    </div>
  );
};
export default File;
