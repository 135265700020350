import React from "react";
import styles from "../../../message-container.scss";

export default ({
  children,
  textColor,
  bgColor,
  textAlign,
  isShowSeenTime,
  isRightSide,
  fontSize,
}) => {
  return (
    <div
      style={{
        color: textColor,
        backgroundColor: bgColor,
        borderRadius: `${
          textAlign === "right" ? "20px 20px 0px 20px" : "20px 20px 20px 0px"
        }`,
        textAlign,
        fontSize: fontSize,
      }}
      className={styles.text}
    >
      {children}
    </div>
  );
};
