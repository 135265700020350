import MessageContainer from './components/message-container';
import Text from './components/message-container/components/text';
import QuickReply from './components/message-container/components/quick-reply';
import Card from './components/message-container/components/card';
import Carousel from './components/message-container/components/carousel';
import Image from './components/message-container/components/image';
import Video from './components/message-container/components/video';
import File from './components/message-container/components/file';
import Typing from './components/message-container/components/typing';

export default {
  MessageContainer,
  BSText: Text,
  BSQuickReply: QuickReply,
  BSCard: Card,
  BSCarousel: Carousel,
  BSImage: Image,
  BSVideo: Video,
  BSFile: File,
  BSTyping: Typing,
};
