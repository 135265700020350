import React from 'react';
import styles from '../../../message-container.scss';

export default ({ children, textColor, bgColor, textAlign, isShowSeenTime, isRightSide, fontSize, style3BorderRadius }) => {
  return (
    <div
      style={{
        color: textColor,
        backgroundColor: bgColor,
        borderRadius: style3BorderRadius ? style3BorderRadius : '10px',
        textAlign,
        fontSize: fontSize,
      }}
      className={styles.text}
    >
      {children}
    </div>
  );
};
