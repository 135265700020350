import React from 'react';
import styles from '../../message-container.scss';
var get = require('lodash.get');
var find = require('lodash.find');

const Button = ({ content, textColor, bgColor, style, handleClickButton, width, isRightSide }) => {
  const handleClick = () => {
    handleClickButton && handleClickButton(content);
    const OpenUrlAction = find(content.actions || [], ['type', 'OPEN_URL']);
    if (OpenUrlAction) {
      if (OpenUrlAction.detail.openInCurrentTab) {
        setTimeout(
          () => {
            window.parent.location.href = OpenUrlAction.detail.detail.url;
          },
          content.actions.length > 1 ? 1000 : 0,
        );
      } else {
        window.parent.open(
          OpenUrlAction.detail.detail.url,
          '_blank', // <- This is what makes it open in a new window.
        );
      }
    }
  };
  return (
    <div
      style={{ width: width || 'fit-content', color: textColor, cursor: isRightSide && 'default' }}
      className={styles.button}
      onClick={handleClick}
    >
      {get(content, 'label') || ''}
    </div>
  );
};

export default Button;
